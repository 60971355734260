

































import { Component, Inject, Prop } from 'vue-property-decorator'

import { AddressDataMixin } from '../../../../shared/mixins/address.mixin'
import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../../../support'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<DefaultAddress>({
  name: 'DefaultAddress'
})
export class DefaultAddress extends AddressDataMixin {
  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Prop({ type: String, required: true })
  public type!: string
}

export default DefaultAddress

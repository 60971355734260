import { AnyObject } from '@movecloser/front-core'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export const REQUEST_RESET_LINK_FORM_COMPONENT_KEY = 'RequestResetLinkFormComponent'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
export const defaultComponentConfig: AnyObject = {
  isAlertUnderForm: false
}






















import { AnyObject } from '@movecloser/front-core'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import { RawLocation } from 'vue-router'

import { Inject } from '../../../../support'
import { ProductOrderData } from '../../../../contexts/orders/contracts/orders'

import { RouteName as CheckoutRoutes } from '../../../checkout/routes'
import { RouteNames as OrderRoutes } from '../../../orders/routes'
import { ProductCartMixin } from '../../../checkout/shared/mixins/product-cart.mixin'
import {
  IProductsRepository,
  ProductsRepositoryType
} from '../../../products/contracts/repositories'
import { RestoreOrderMixin } from '../../../shared/mixins/restoreOrder.mixin'

import { LastOrderItemType, PossibleItemAction } from './LastOrderItem.contracts'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<LastOrderItem>({
  name: 'LastOrderItem'
})
export class LastOrderItem extends Mixins(ProductCartMixin, RestoreOrderMixin) {
  @Inject(ProductsRepositoryType)
  protected readonly productsRepository!: IProductsRepository

  @Prop({ type: String, required: true })
  public readonly action!: string

  @Prop({ type: Array, required: true })
  public readonly data!: AnyObject

  @Prop({ type: String, required: true })
  public readonly headline!: string

  @Prop({ type: String, required: true })
  public readonly type!: string

  public actionType = PossibleItemAction

  public get computedAction (): string {
    return this.action
  }

  public get target (): RawLocation {
    if (this.type === LastOrderItemType.Order) {
      return {
        name: `orders.${OrderRoutes.Order}`,
        params: { id: String(this.data[0].id) }
      }
    }

    /** LastOrderItemType.AbandonedCart */
    return {
      name: `checkout.${CheckoutRoutes.Cart}`
    }
  }

  public get label (): string {
    return String(this.$t(`front.profile.molecules.LastOrderItem.btnLabel.${this.action}`))
  }

  /**
   * Handle redirect to cart
   */
  public async handleCartRestore (): Promise<RawLocation | undefined> {
    if (!this.cartService) {
      return
    }

    /**
     * @inheritDoc
     */
    await this.createNewCartFromOrder(this.data[0].products as ProductOrderData[])

    if (this.isSuccessful) {
      this.$emit('deleteRestoredOrder', this.data.id)
    }
  }
}

export default LastOrderItem

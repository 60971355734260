






import { Component, Mixins } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { StructureConfigurable } from '../../../../support/mixins'

import { ChangeAddressForm } from '../../organisms/ChangeAddressForm'
import { ChangeEmailForm } from '../../organisms/ChangeEmailForm'
import { ChangeNewsletterPreferencesForm } from '../../organisms/ChangeNewsletterPreferencesForm'
import { ChangePasswordForm } from '../../organisms/ChangePasswordForm'
import { ChangeUserDetailsForm } from '../../organisms/ChangeUserDetailsForm'
import { ProfileWrapper } from '../../molecules/ProfileWrapper'

import {
  PROFILE_VIEW_CONFIG_MAP,
  PROFILE_VIEW_KEY,
  ProfileLayout,
  ProfileViewConfig, profileViewLayoutRegistry
} from './ProfileView.config'
import { UserMixin } from '../../shared'

/**
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl>
 */
@Component<Profile>({
  name: 'MyData',
  components: {
    ChangeAddressForm,
    ChangeEmailForm,
    ChangeNewsletterPreferencesForm,
    ChangePasswordForm,
    ChangeUserDetailsForm,
    ProfileWrapper
  },
  created () {
    this.config = this.getComponentConfig(PROFILE_VIEW_KEY, { ...PROFILE_VIEW_CONFIG_MAP })
  }
})
export class Profile extends Mixins(StructureConfigurable, UserMixin) {
  protected config!: ProfileViewConfig

  public get component (): VueConstructor | undefined {
    const layout = this.getConfigProperty<string>('layout')

    if (!Object.values(ProfileLayout).includes(layout as ProfileLayout)) {
      return
    }

    return profileViewLayoutRegistry[layout]
  }

  /**
   * Specify which data types should be shown in myData profile view
   */
  public get myDataContentTypes (): Array<string> {
    return this.getConfigProperty<Array<string>>('myDataContentTypes')
  }
}

export default Profile
